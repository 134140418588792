<script setup>
import { reactive, ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";

var permissions = reactive([]);
const loading = ref(false);

async function getPermissions() {
  loading.value = true;
  try {
    const testing = await apiQuery("J24Auth", "getpermission", "");
    //console.log(testing);
    permissions.length = 0;
    permissions.push(...testing);
    //permissions = testing;
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

async function deletePermission(permission) {
  permission.deleted = true;
  console.log(permission);
  loading.value = true;
  const result = await apiQuery("J24Auth", "updatepermission", permission);
  loading.value = false;
  console.log(result);
}

const addingNew = ref(false);
var newPermission = reactive({
  permissionName: "",
  app: "",
});

async function addNew() {
  loading.value = true;
  const result = await apiQuery("J24Auth", "addpermission", newPermission);
  getPermissions();
  addingNew.value = false;
}

onMounted(() => {
  getPermissions();
});
</script>

<template>
  <div v-if="addingNew">
    <h1>Add New Permission</h1>
    <v-form>
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              density="compact"
              v-model="newPermission.app"
              label="Application"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              density="compact"
              label="Permission Name"
              v-model="newPermission.permissionName"
            ></v-text-field>
          </v-col>
          <v-col><v-btn @click="addNew">Add</v-btn></v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
  <h1>Permissions</h1>

  <v-dialog v-model="loading" hide-overlay persistent>
    <v-card color="grey">
      <v-card-text>
        Loading
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-btn @click="addingNew = !addingNew">Add New</v-btn>
  <div v-if="permissions.length">
    <v-table>
      <thead>
        <tr>
          <th>Application</th>
          <th>Permission Name</th>
          <th>Actions</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="permission in permissions" :key="permission._id">
          <td>{{ permission.app }}</td>
          <td>{{ permission.permissionName }}</td>
          <td>
            <div v-for="item in permission.actions" :key="item">
              {{ item }}<br />
            </div>
          </td>
          <td>
            <v-icon @click="deletePermission(permission)">mdi-delete</v-icon>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>
