<script setup>
import { Auth } from "aws-amplify";
import { onMounted } from "vue";

onMounted(() => {
  Auth.signOut();
});
</script>

<template>
  <h4>You have been signed out</h4>
</template>
