<template>
  <v-select
    class="ma-0"
    hide-details="auto"
    v-if="appBar.type == 'select'"
    density="compact"
    variant="plain"
    :label="appBar.header"
    :items="appBar.items"
    v-model="appBar.selected"
  ></v-select>
</template>

<script setup>
import { appBarStore } from "@/stores/appBar";
import { storeToRefs } from "pinia";

const appBar = appBarStore();
// const { type, header } = storeToRefs(appBar);
</script>
