<template>
  <v-row>
    <v-col>
      <h3>{{ title }}</h3>
    </v-col>
  </v-row>
  <v-row>
    <v-col>{{ topText }}</v-col>
  </v-row>

  <template v-if="!csvContents.length">
    <v-row>
      <v-col>
        <v-file-input
          v-if="!csvContents.length"
          accept=".csv"
          label="Select CSV File"
          @change="parse_csv"
          density="compact"
        />
      </v-col>
      <v-col>
        <download-csv :data="blankCSV()"
          ><v-btn size="small" prepend-icon="mdi-download"
            >Download Template</v-btn
          ></download-csv
        >
      </v-col>
    </v-row>
  </template>

  <template v-if="csvErrorContents.length">
    <h3>The following will not be imported due to errors</h3>
    <EasyDataTable
      class="mt-3"
      :headers="dataTableHeaders()"
      :items="csvErrorContents"
      theme-color="#e7541e"
      alternating
      buttons-pagination
    />
    <v-alert
      class="mt-3"
      v-if="csvErrors != ''"
      type="error"
      icon="mdi-alert"
      >{{ csvErrors }}</v-alert
    >
  </template>
  <template v-if="csvContents.length">
    <h3>The following will be imported. Please confirm</h3>
    <EasyDataTable
      class="mt-3"
      :headers="dataTableHeaders()"
      :items="csvContents"
      theme-color="#e7541e"
      alternating
      buttons-pagination
    />
  </template>
  <v-row>
    <v-col class="text-left"
      ><v-btn
        class="mt-3"
        v-if="csvContents.length"
        @click="completeImport()"
        :disabled="saving"
        >Import</v-btn
      ></v-col
    >
    <v-col><v-spacer /></v-col>
    <v-col class="text-right">
      <v-btn @click="$emit('cancel')" class="mt-3">Cancel</v-btn>
    </v-col>
  </v-row>
  <v-alert class="mt-3" v-if="alert != ''" type="error" icon="mdi-alert">{{
    alert
  }}</v-alert>
</template>

<script setup>
import { reactive, ref, onMounted, defineProps, defineEmits } from "vue";
import {
  assetsHeaders,
  contactsHeaders,
  deliveryHeaders,
  orderHeaders,
} from "./cat24Types";
import { apiQuery } from "@/api/j24api";
import { appBarStore } from "@/stores/appBar";

const appBar = appBarStore();
//import { papa } from "papaparse";
// eslint-disable-next-line
const papa = require("papaparse");

//const fileUploaded = ref(false);
const csvContents = reactive([]);
const csvErrorContents = reactive([]);
const csvErrors = ref("");
const saving = ref(false);

const props = defineProps({
  importType: String,
});

const emit = defineEmits(["cancel", "updated"]);

async function parse_csv(event) {
  let files = event.target.files || event.datatransfer.files;
  if (!files.length) return console.log("Invalid File");

  var file = event.target.files[0];
  await papa.parse(file, {
    header: true,
    complete: (results) => {
      csvContents.length = 0;
      csvErrorContents.length = 0;
      csvErrors.value = "";
      console.log(results);
      validateCsv(results);
      //   results.data.forEach((element) => {
      //     csvContents.push(element);
      //   });
      console.log("CSV Contents", csvContents);
    },
  });
}

function validateCsv(results) {
  if (results.errors.length > 0) {
    csvErrors.value =
      "There were errors importing the CSV, please check data carefully. ";
  }
  results.data.forEach((result) => {
    if (checkRequiredFields(result)) {
      csvContents.push(result);
    } else {
      csvErrorContents.push(result);
    }
  });
}

function checkRequiredFields(record) {
  var statusGood = true;
  console.log(record);
  headersSelected.forEach((header) => {
    if (header.required) {
      console.log(header);
      // eslint-disable-next-line
      if (!record.hasOwnProperty(header.value)) {
        console.log(header);
        statusGood = false;
        csvErrors.value += "Required field '" + header.value + "' is missing. ";
      } else {
        if (record[header.value] == "") {
          csvErrors.value +=
            "Required field '" + header.value + "' is null value. ";
          statusGood = false;
        }
      }
    }
  });
  return statusGood;
}

const assetsTopText =
  "Assets can be imported in bulk from a csv file. Note that the first line of the file should be the field headers. A template can be downloaded by clicking the button below.";
const assetsTitle = "Import Asset Data";

const contactsTopText =
  "Contacts can be imported in bulk from a csv file. Note that the first line of the file should be the field headers. A template can be downloaded by clicking the button below.";
const contactsTitle = "Import Contacts Data";

const deliveriesTopText =
  "Delivery slots can be imported in bulk from a csv file. Note that the first line of the file should be the field headers. A template can be downloaded by clicking the button below.";
const deliveriesTitle = "Import Delivery Slots";

const ordersTopText =
  "Orders can be imported in bulk from a csv file. Note that the first line of the file should be the field headers. A template can be downloaded by clicking the button below.";
const ordersTitle = "Import Orders";

const title = ref("");
const topText = ref("");
var headersSelected = reactive([]);

function dataTableHeaders() {
  const result = [];
  headersSelected.forEach((header) => {
    result.push({ text: header.text, value: header.value });
  });
  return result;
}

function blankCSV() {
  var data = {};
  headersSelected.forEach((header) => {
    data[header.value] = "";
  });
  return [data];
}

onMounted(() => {
  console.log(props);
  switch (props.importType) {
    case "assets":
      console.log("Assets Selected");
      title.value = assetsTitle;
      topText.value = assetsTopText;
      assetsHeaders.forEach((element) => {
        headersSelected.push(element);
      });

      console.log("Headers Selected - ", headersSelected);
      break;
    case "contacts":
      title.value = contactsTitle;
      topText.value = contactsTopText;
      contactsHeaders.forEach((element) => {
        headersSelected.push(element);
      });
      break;
    case "deliveries":
      title.value = deliveriesTitle;
      topText.value = deliveriesTopText;
      deliveryHeaders.forEach((element) => {
        headersSelected.push(element);
      });
      break;
    case "orders":
      title.value = ordersTitle;
      topText.value = ordersTopText;
      orderHeaders.forEach((element) => {
        headersSelected.push(element);
      });
      break;
    default:
      console.log("No Type Prop set for cat24ImportCSV");
  }
});

var csvContent = ref([]);
const importCSV = ref(false);
const continueImport = ref(false);
const hasHeadersIndicator = ref(false);

const alert = ref("");

async function completeImport() {
  saving.value = true;
  console.log("Starting Import");
  var importedRows = 0;
  try {
    const chunkSize = 2000;
    for (let i = 0; i < csvContents.length; i += chunkSize) {
      console.log("Chunking - Start Point " + i);
      const chunk = csvContents.slice(i, i + chunkSize);
      const payload = {
        apiAction: "add",
        customer: appBar.selected,
        [props.importType]: chunk,
      };
      const result = await apiQuery(
        "ELA",
        "cat24/" + props.importType,
        payload
      );
      importedRows = i + chunkSize;
    }
    emit("updated");
  } catch (error) {
    if (importedRows > 0) {
      alert.value =
        "Error Importing Records, however rows up to " +
        importedRows +
        " were imported correctly";
    } else {
      alert.value = "Error Adding Records";
    }

    console.log(error);
    saving.value = false;
  }

  //   const payload = {
  //     apiAction: "add",
  //     customer: appBar.selected,
  //     [props.importType]: csvContents,
  //   };
  //   const result = await apiQuery("ELA", "cat24/" + props.importType, payload);
  //   console.log(result);
  //   emit("updated");
  // } catch (error) {
  //   alert.value = "Error Adding Records";
  //   console.log(error);
  //   saving.value = false;
  // }
  // console.log("Complete Import");
}
</script>
