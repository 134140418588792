<script setup>
import { watch, reactive, ref, onMounted } from "vue";
import { apiQuery } from "@/api/j24api";

const loading = ref(true);
const groups = reactive([]);
const editGroupRecord = reactive({
  _id: "",
  name: "",
  groupID: "",
  roles: [],
  permissions: [],
});

const editGroup = ref(false);
const newGroup = ref(false);

const permissions = reactive([]);
const permissionsFiltered = reactive([]);
const customers = reactive([]);
const apps = reactive([]);

const addPermission = ref(false);
const addPermissionApp = ref("");
const addPermissionScope = ref("");
const addPermissionSelected = ref("");
const addPermissionActions = ref([]);
const addPermissionSelectedActions = ref([]);

const addRole = ref(false);
const addRoleScope = ref("");
const addRoleSelected = ref("");

const roles = reactive([]);

function getRoleName(id) {
  var roleName = "Role Not Found (has it been deleted?)";
  roles.forEach((role) => {
    if (role._id == id) {
      roleName = role.name;
    }
  });
  return roleName;
}

async function getGroups() {
  loading.value = true;
  try {
    const result = await apiQuery("J24Auth", "groups", { apiAction: "get" });
    groups.length = 0;
    groups.push(...result);
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
}

async function getPermissionsCustomers() {
  permissions.length = 0;
  var result = await apiQuery("J24Auth", "getpermission", "");
  const tempApps = [];
  for (const key in result) {
    result[key].value = result[key].permissionName;
    result[key].title = result[key].permissionName;
    tempApps.push(result[key].app);
  }
  permissions.push(...result);

  apps.length = 0;
  apps.push(...new Set(tempApps));
  customers.length = 0;
  result = await apiQuery("J24Auth", "customers", { apiAction: "get" });
  for (const key in result) {
    result[key].value = result[key].name;
    result[key].title = result[key].name;
  }
  customers.push({ value: "*", title: "* (All Customers)" });
  customers.push(...result);
}

watch(addPermissionApp, (currentValue) => {
  console.log("Filtering Permissions");
  permissionsFiltered.length = 0;
  for (const key in permissions) {
    if (permissions[key].app == currentValue) {
      permissionsFiltered.push(permissions[key]);
    }
  }
});

watch(addPermissionSelected, (currentValue) => {
  console.log("Filtering Actions");
  addPermissionActions.value.length = 0;
  for (const key in permissions) {
    if (
      permissions[key].app == addPermissionApp.value &&
      permissions[key].permissionName == currentValue
    ) {
      addPermissionActions.value.push(...permissions[key].actions);
    }
  }
});

function addPermissionSave() {
  // Add to editGroupRecord.permissions

  editGroupRecord.permissions.push({
    app: addPermissionApp.value,
    permissionName: addPermissionSelected.value,
    actions: [...addPermissionSelectedActions.value],
    scope: addPermissionScope.value,
  });
  addPermissionApp.value = "";
  addPermissionSelected.value = "";
  addPermissionSelectedActions.value.length = 0;
  addPermissionScope.value = "";
  addPermission.value = false;
}

async function getRoles() {
  const result = await apiQuery("J24Auth", "roles", { apiAction: "get" });
  roles.length = 0;
  for (const key in result) {
    result[key].title = result[key].name;
    result[key].value = result[key].name;
  }
  roles.push(...result);
}

function addRoleSave() {
  // Get ID of Role Selected
  var roleID;
  roles.forEach((role) => {
    if (role.name == addRoleSelected.value) {
      roleID = role._id;
    }
  });
  editGroupRecord.roles.push({ roleID: roleID, scope: addRoleScope });
}

async function newGroupPressed(key) {
  loading.value = true;
  delete editGroupRecord._id;
  await getPermissionsCustomers();
  newGroup.value = true;
  loading.value = false;
}

async function editGroupPressed(key) {
  loading.value = true;
  editGroupRecord._id = groups[key]._id;
  editGroupRecord.name = groups[key].name;
  editGroupRecord.groupID = groups[key].groupID;
  editGroupRecord.roles.push(...groups[key].roles);
  editGroupRecord.permissions.push(...groups[key].permissions);
  editGroup.value = true;
  await getPermissionsCustomers();
  //await getRoles();
  loading.value = false;
}

function cancelEditAdd() {
  editGroupRecord._id = "";
  editGroupRecord.name = "";
  editGroupRecord.groupID = "";
  editGroupRecord.roles.length = 0;
  editGroupRecord.permissions.length = 0;
  editGroup.value = false;
  newGroup.value = false;
}
onMounted(() => {
  console.log("Onmounted triggered");
  getGroups();
  getRoles();
});

async function saveGroup() {
  loading.value = true;
  var action = "add";
  if (editGroup.value) {
    action = "update";
  }
  await apiQuery("J24Auth", "groups", {
    apiAction: action,
    ...editGroupRecord,
  });
  cancelEditAdd();
  getGroups();
  loading.value = false;
}
</script>

<template>
  <h1>Groups Management</h1>
  <v-icon @click="newGroupPressed()">mdi-plus-circle</v-icon>Add New
  <br />
  <!-- Add Edit Form -->
  <v-form v-if="editGroup || newGroup">
    <v-text-field
      v-model="editGroupRecord.name"
      label="Group Name"
    ></v-text-field>
    <v-text-field
      v-model="editGroupRecord.groupID"
      label="Group Identifier"
    ></v-text-field>
    <h3>Roles</h3>
    <v-icon @click="addRole = true">mdi-plus-circle</v-icon>

    <!-- Add Role  -->
    <template v-if="addRole">
      <v-select
        v-model="addRoleScope"
        :items="customers"
        label="Choose Scope of Role"
      ></v-select>
      <v-select
        v-model="addRoleSelected"
        :items="roles"
        label="Choose Role"
      ></v-select>
      <v-btn @click="addRoleSave()">Add Role</v-btn
      ><v-btn @click="addRole = false">Cancel</v-btn>
    </template>
    <!-- List Roles -->
    <v-table>
      <thead class="text-left">
        <th>Scope</th>
        <th>Role Name</th>
        <th></th>
      </thead>
      <tbody>
        <tr
          v-for="(role, key) in editGroupRecord.roles"
          :key="role"
          class="text-left"
        >
          <td>{{ role.scope }}</td>
          <td>{{ getRoleName(role.roleID) }}</td>
          <td>
            <v-btn @click="editGroupRecord.roles.splice(key, 1)">Delete</v-btn>
          </td>
        </tr>
      </tbody>
    </v-table>
    <h3>Permissions</h3>
    <v-icon @click="addPermission = true">mdi-plus-circle</v-icon>

    <!-- Add Permission -->
    <template v-if="addPermission">
      <v-select
        v-model="addPermissionScope"
        :items="customers"
        label="Choose Scope of Permission"
      ></v-select>
      <v-select
        v-model="addPermissionApp"
        :items="apps"
        label="Choose App"
      ></v-select>
      <v-select
        v-model="addPermissionSelected"
        :items="permissionsFiltered"
        label="Choose Permission to add"
      ></v-select>
      <v-combobox
        v-model="addPermissionSelectedActions"
        :items="addPermissionActions"
        label="Choose Actions"
        multiple
      ></v-combobox>
      <v-btn @click="addPermissionSave">Add Permission</v-btn
      ><v-btn @click="addPermission = false">Cancel</v-btn>
    </template>
    <v-table>
      <thead class="text-left">
        <th>Scope</th>
        <th>App</th>
        <th>Permission Name</th>
        <th>Actions</th>
        <th></th>
      </thead>
      <tbody>
        <tr
          v-for="(permission, key) in editGroupRecord.permissions"
          :key="permission"
          class="text-left"
        >
          <td>{{ permission.scope }}</td>
          <td>{{ permission.app }}</td>
          <td>{{ permission.permissionName }}</td>
          <td>{{ permission.actions }}</td>
          <td>
            <v-btn @click="editGroupRecord.permissions.splice(key, 1)"
              >Delete</v-btn
            >
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-btn @click="saveGroup()">Save</v-btn
    ><v-btn @click="cancelEditAdd()">Cancel</v-btn>
  </v-form>
  <!-- List Groups-->
  <v-table v-if="!newGroup && !editGroup">
    <thead class="text-left">
      <th>Group Name</th>
      <th></th>
    </thead>
    <tbody v-if="groups.length" class="text-left">
      <tr v-for="(group, key) in groups" :key="group">
        <td>{{ group.name }}</td>
        <td><v-icon @click="editGroupPressed(key)">mdi-pencil</v-icon></td>
      </tr>
    </tbody>
  </v-table>

  <!-- Loading Dialog -->
  <v-dialog v-model="loading" hide-overlay persistent>
    <v-card color="grey">
      <v-card-text>
        Loading
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
