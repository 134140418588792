<script setup>
import { Auth } from "aws-amplify";
import { ref } from "vue";
import QrcodeVue from "qrcode.vue";

var totpCode = ref("");
var enablingMFA = ref(false);
var verifyCode = ref("");
var title = ref("Please enable MFA for your user");
var complete = ref(false);

async function enableMFA() {
  const user = await Auth.currentAuthenticatedUser();
  totpCode.value = await Auth.setupTOTP(user);
  totpCode.value =
    "otpauth://totp/Jigsaw24:" +
    user.attributes.email +
    "?secret=" +
    totpCode.value;
  enablingMFA.value = true;
  console.log(totpCode);
}

var verifyCodeLabel = ref("Verify Code");
async function verifyMFA() {
  const user = await Auth.currentAuthenticatedUser();
  Auth.verifyTotpToken(user, verifyCode.value)
    .then((answer) => {
      console.log(answer);
      Auth.setPreferredMFA(user, "TOTP");
      complete.value = true;
      enablingMFA.value = false;
      title.value = "Thank You, please choose App from menu";
    })
    .catch((error) => {
      console.log("MFA Failed");
      console.log(error);
      verifyCodeLabel.value = "Verify failed please try again";
    });
}
</script>

<template>
  <v-main>
    <h4>{{ title }}</h4>
    <div v-if="!enablingMFA && !complete">
      <button @click="enableMFA">Enable</button>
    </div>
    <div v-if="enablingMFA">
      <h4>Scan the QR Code</h4>
      <qrcode-vue :value="totpCode"></qrcode-vue>
      <v-form>
        <v-text-field
          v-model="verifyCode"
          :label="verifyCodeLabel"
        ></v-text-field>
        <v-btn class="mr-4" type="submit" @click="verifyMFA">Verify</v-btn>
      </v-form>
    </div>
  </v-main>
</template>
