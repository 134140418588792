<template>
  <v-app-bar app dense class="elevation-0">
    <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
    <v-row no-gutters>
      <v-col cols="1">
        <v-menu>
          <template v-slot:activator="{ props }">
            <div v-bind="props" class="pointer">
              <v-icon class="pl-5">mdi-dots-grid</v-icon>
              <!-- <font-awesom-icon icon="fa-solid fa-laptop"></font-awesom-icon> -->
            </div>
          </template>
          <v-card class="pa-2">
            <v-list rounded>
              <v-list-title class="pb-2">Apps</v-list-title>
              <v-list-item
                v-for="navApp in navApps"
                :key="navApp.appName"
                :to="navApp.link"
                variant="text"
              >
                <v-list-item-icon
                  :icon="navApp.icon"
                  start
                  :color="navApp.color"
                ></v-list-item-icon>

                <v-list-item-titile v-text="navApp.appName" />
              </v-list-item>
              <!-- <v-list-item>
              <div>
                <v-row>
                  <v-col>
                    <h4>Apps</h4>
                  </v-col>
                </v-row>
                <v-row><v-col v-for="navApp in navApps" :key="navApp.appName"></v-col> </v-row>

                <v-card
                  class="d-flex align-content-start flex-wrap"
                  color="grey lighten-2"
                  flat
                  tile
                  min-height="200"
                  max-width="500"
                >
                  <v-card
                    v-for="navApp in navApps"
                    :key="navApp.appName"
                    class="pa-2"
                    outlined
                    tile
                    min-width="150"
                    link
                  >
                    <router-link
                      style="text-decoration: none; color: inherit"
                      :to="navApp.link"
                    >
                      <div class="text-center">
                        <v-icon class="text-center" :color="navApp.color">{{
                          navApp.icon
                        }}</v-icon
                        ><br />
                        {{ navApp.appName }}
                      </div>
                    </router-link>
                  </v-card>
                </v-card> -->
              <!-- </div> -->
              <!-- <v-list-item-title
            ><router-link to="cat24/assetsUpload">
              Cat 24 Admin
            </router-link></v-list-item-title
          > -->
              <!-- </v-list-item> -->
            </v-list>
          </v-card>
        </v-menu>
      </v-col>
      <v-col>
        <NavBarDynamic />
      </v-col>
      <v-spacer></v-spacer>
      <!-- <router-link to="/">
      <v-img
        src="../assets/j24_logo.svg"
        alt="aaa"
        min-height="45"
        min-width="140"
        max-height="100"
      />
    </router-link> -->
      <!-- <v-spacer></v-spacer> -->
      <v-col class="text-right">
        <div v-if="isAuthenticated" class="pointer">
          <v-menu rounded="rounded">
            <template v-slot:activator="{ props }">
              <!-- <v-btn v-bind="props"> -->

              <div v-bind="props">
                {{ firstname }} {{ lastname }}

                <v-icon>mdi-account</v-icon>
              </div>

              <!-- </v-btn> -->
            </template>
            <v-list color="white" rounded shaped>
              <v-list-item link>
                <v-list-item-title>
                  <!-- <v-btn @click="logoutAndRedirect"> Sign Out </v-btn> -->
                  <router-link
                    to="userProfile"
                    style="text-decoration: none; color: inherit"
                    >Profile</router-link
                  >
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="logoutAndRedirect">
                  Sign out
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <v-btn @click="login">
            <v-list-item-title class="text-h6">Sign-In </v-list-item-title>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<style>
.v-toolbar__content {
  border-bottom: 2px solid lightgray;
  background: #f9f9f9;
}
.pointer {
  cursor: pointer;
}
</style>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
//import AuthService from "@/auth/AuthService";
import { Auth } from "aws-amplify";
import { appBarStore } from "@/stores/appBar";
import NavBarDynamic from "./NavBarDynamic.vue";

const navApps = ref([
  {
    appName: "CAT24 Admin",
    icon: "mdi-truck-fast",
    link: "cat24/contactsUpload",
    color: "#e7541e",
  },
  // {
  //   appName: "Jamf Dashboard",
  //   icon: "mdi-desktop-mac-dashboard",
  //   link: "",
  // },
  {
    appName: "User Admin",
    icon: "mdi-account-multiple",
    link: "admin/Permissions",
    color: "black",
  },
]);

function login() {
  //AuthService.signIn();
  Auth.federatedSignIn();
  console.log("Login");
}
async function getUser() {
  try {
    const temp = await Auth.currentAuthenticatedUser();
    console.log(temp);
  } catch (error) {
    console.log(error);
  }
}
function logoutAndRedirect() {
  Auth.signOut();
}

const isAuthenticated = ref(false);
var userObj = reactive({
  email: "",
  lastname: "",
  firstname: "",
});
const email = ref("");
const lastname = ref("");
const firstname = ref("");

onMounted(() => {
  Auth.currentAuthenticatedUser()
    .then((currentAuthenticatedUser) => {
      if (currentAuthenticatedUser) {
        isAuthenticated.value = true;
        email.value =
          currentAuthenticatedUser.signInUserSession.idToken.payload.email;
        lastname.value =
          currentAuthenticatedUser.signInUserSession.idToken.payload.family_name;
        firstname.value =
          currentAuthenticatedUser.signInUserSession.idToken.payload.given_name;
      }

      console.log("Hello");
      console.log(currentAuthenticatedUser);

      const userInfo = Auth.currentUserInfo();
      userInfo.then((result) => {
        console.log("User Info");
        console.log(result);
      });
      //return { userObj };
    })
    .catch(() => {
      console.log("User not Authenticated");
    });
});
</script>
