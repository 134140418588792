<template>
  <template v-if="!appBar.selected.length">
    <h3>Select Customer from Menu above</h3>
  </template>
  <template v-else>
    <cat24ImportCSV
      v-if="importCSV"
      @cancel="importCSV = !importCSV"
      importType="orders"
    />
    <cat24AddRow
      v-if="addRow"
      addType="orders"
      @cancel="addRow = !addRow"
      @updated="(addRow = !addRow), getData()"
    />
    <cat24AddRow
      v-if="editRow"
      edit
      :item="editItem"
      addType="orders"
      @cancel="editRow = !editRow"
      @updated="(editRow = !editRow), getData()"
    />
    <template v-if="!selectedOrder">
      <v-row no-gutters style="height: 35px">
        <!-- <v-col class="flex-grow-0 flex-shrink 0" cols="2"> </v-col> -->
        <v-col>
          <v-select
            label="Search Field"
            density="compact"
            variant="underlined"
            v-model="searchFieldSelected"
            :items="searchFields()"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-text-field
            variant="underlined"
            height="25px"
            class="pl-2"
            density="compact"
            label="Search Value"
            v-model="searchValue"
          ></v-text-field>
        </v-col>

        <v-spacer></v-spacer>
        <v-col class="text-right">
          <v-btn
            height="33px"
            prepend-icon="mdi-plus"
            size="small"
            class="pa-2 ml-3"
            @click="addRow = true"
            >Add New</v-btn
          >
        </v-col>
      </v-row>

      <EasyDataTable
        :headers="headers"
        :items="items"
        theme-color="#e7541e"
        alternating
        buttons-pagination
        class="mt-3"
        :loading="loading"
        :search-field="searchField"
        :search-value="searchValue"
        @click-row="selectOrder"
      >
        <template #item-actions="item">
          <v-icon @click="openEdit(item)">mdi-pencil</v-icon>
        </template>
      </EasyDataTable>
    </template>
    <template v-if="selectedOrder">
      <v-row>
        <v-col>
          <v-btn
            @click="selectedOrder = false"
            height="33px"
            size="small"
            prepend-icon="mdi-arrow-left"
          >
            Back
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right"
          ><v-btn
            height="33px"
            size="small"
            prepend-icon="mdi-delete"
            @click="displayConfirmDelete = true"
            >Delete Order</v-btn
          ></v-col
        >
      </v-row>
      <v-table>
        <!-- <t-head>
          <th colspan="2" class="text-center">Order Details</th>
        </t-head> -->
        <tbody>
          <template v-for="item in selectedOrderDetails" :key="item">
            <tr>
              <th>{{ item.label }}</th>
              <td>{{ item.value }}</td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </template>
    <v-dialog v-model="displayConfirmDelete" persistent
      ><v-card>
        <v-card-title>Confirm Delete Order</v-card-title>
        <v-card-text
          >Please confirm that you wish to delete this order, this action cannot
          be undone.</v-card-text
        >
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn @click="displayConfirmDelete = false">Cancel</v-btn
          ><v-btn @click="confirmDelete()">Delete</v-btn></v-card-actions
        >
      </v-card></v-dialog
    >
  </template>

  <!-- <h4>{{ token }}</h4>
  <div v-if="token">
    <h4>{{ token }}</h4>
  </div> -->
</template>

<style>
.v-select__selection-text {
  font-size: 0.9em;
}
</style>
<script setup>
import { reactive, ref, onMounted, watch } from "vue";
//import type { Header, Item } from "vue3-easy-data-table";
import cat24ImportCSV from "../cat24ImportCSV.vue";
import cat24AddRow from "../cat24AddRow.vue";
import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";

const appBar = appBarStore();
const items = reactive([]);
const selectedOrder = ref(false);

appBar.$subscribe((mutation, state) => {
  console.log("State Changed");
  if (appBar.selected != "") {
    getData();
  }
});

const headers = [
  { text: "Order ID", value: "_id" },
  { text: "Email", value: "email" },
];

const searchField = ref("");
const searchValue = ref("");
const searchFieldSelected = ref("");

function searchFields() {
  var result = [];
  headers.forEach((header) => {
    if (header.text != "Actions") result.push(header.text);
  });
  return result;
}

watch(searchFieldSelected, (currentValue) => {
  headers.forEach((header) => {
    if (header.text == currentValue) {
      searchField.value = header.value;
    }
  });
});
const importCSV = ref(false);
const addRow = ref(false);
const editItem = ref({});
const editRow = ref(false);
const loading = ref(false);

const selectedOrderDetails = reactive([]);
const selectedOrderId = ref("");
const selectOrder = async (item) => {
  console.log(item);
  loading.value = true;

  selectedOrderDetails.length = 0;

  const response = await apiQuery("ELA", "cat24/orders", {
    apiAction: "getOne",
    customer: appBar.selected,
    order: {
      _id: item._id,
    },
  });
  if (response._id) {
    selectedOrderId.value = item._id;
    var details = [];
    details.push({ label: "Order ID", value: response._id });
    details.push(
      checkForValue(response, "readyForDelivery", "Ready for Delivery")
    );
    details.push(checkForValue(response, "_created_at", "Created At"));
    details.push(checkForValue(response, "_updated_at", "Updated At"));
    details.push(checkForValue(response, "quantity", "Quantitiy"));
    if (response.deliverySlot) {
      details.push(
        checkForValue(response.deliverySlot, "deliveryDate", "Delivery Date")
      );
    }
    if (response.contact) {
      details.push({ label: "Contact Details", value: "" });
      details.push(checkForValue(response.contact, "email", "Email"));
      details.push(checkForValue(response.contact, "firstName", "First Name"));
      details.push(checkForValue(response.contact, "lastName", "Last Name"));
      details.push(checkForValue(response.contact, "keyWords", "Keywords"));
    }
    if (response.orderingChoice) {
      details.push({ label: "Order Choice", value: "" });
      details.push(checkForValue(response.orderingChoice, "name", "Name"));
      details.push(
        checkForValue(
          response.orderingChoice,
          "requiresDelivery",
          "Requires Delivery"
        )
      );
      details.push(
        checkForValue(
          response.orderingChoice,
          "placeOrderOnSid",
          "Place Order on SID"
        )
      );
      details.push(
        checkForValue(
          response.orderingChoice,
          "requiresBooking",
          "Requires Booking"
        )
      );
      details.push(
        checkForValue(
          response.orderingChoice,
          "requiresCollection",
          "Requires Collection"
        )
      );
      details.push(
        checkForValue(
          response.orderingChoice,
          "returnExistingDevice",
          "Return Existing Device"
        )
      );
    }
    if (response.deliveryAddress) {
      details.push({ label: "Delivery Address", value: "" });
      details.push(checkForValue(response.deliveryAddress, "line1", "Line 1"));
      details.push(checkForValue(response.deliveryAddress, "line2", "Line 2"));
      details.push(checkForValue(response.deliveryAddress, "city", "City"));
      details.push(
        checkForValue(response.deliveryAddress, "postcode", "Post Code")
      );
      details.push(
        checkForValue(response.deliveryAddress, "driverNotes", "Driver Notes")
      );
      details.push(
        checkForValue(
          response.deliveryAddress,
          "contactNumber",
          "Contact Number"
        )
      );
    }

    // Remove any null entries from temp array and push into reactive array
    details = details.filter((element) => {
      return element != null;
    });
    selectedOrderDetails.push(...details);
  }
  loading.value = false;
  selectedOrder.value = true;
};

function checkForValue(object, property, label) {
  if (Object.prototype.hasOwnProperty.call(object, property)) {
    return { label: label, value: object[property] };
  }
}

const displayConfirmDelete = ref(false);

async function confirmDelete() {
  console.log("confirmedDeleted");
  selectedOrder.value = false;
  loading.value = true;
  displayConfirmDelete.value = false;
  const response = await apiQuery("ELA", "cat24/orders", {
    apiAction: "delete",
    customer: appBar.selected,
    order: {
      _id: selectedOrderId.value,
    },
  });

  getData();
}

function openEdit(item) {
  editItem.value = item;
  editRow.value = true;
}

async function getData() {
  loading.value = true;
  const response = await apiQuery("ELA", "cat24/orders", {
    apiAction: "get",
    customer: appBar.selected,
  });
  console.log(response);
  items.length = 0;
  response.forEach((element) => {
    items.push(element);
  });
  loading.value = false;
}

onMounted(async () => {
  if (appBar.selected != "") {
    getData();
  }
});
</script>
