import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { useRouteGuard } from "@/auth/useRouterGuard";

import UserProfile from "../views/UserProfile.vue";
import cat24SideBar from "../views/cat24/cat24SideBar.vue";
import AssetsBulkUploader from "../views/cat24/Assets/AssetsBulkUploader.vue";
import ContactsBulkUploader from "../views/cat24/Contacts/ContactsBulkUploader.vue";
import BookingSlots from "../views/cat24/BookingSlots/BookingSlots.vue";
import OrdersBulkUploader from "../views/cat24/Orders/OrdersBulkUploader.vue";
import SignOut from "../views/SignOut.vue";
import SignIn from "../views/SignIn.vue";
import EnableMFA from "../views/EnableMFA.vue";
import adminSideBar from "../views/admin/adminSideBar.vue";
import adminPermissions from "../views/admin/adminPermissions.vue";
import adminRoles from "../views/admin/adminRoles.vue";
import adminCustomers from "../views/admin/adminCustomers.vue";
import adminGroups from "../views/admin/adminGroups.vue";
import adminUsers from "../views/admin/adminUsers.vue";
import loginTest from "../views/LoginTest.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/logintest",
    name: "loginTest",
    component: loginTest,
  },
  {
    path: "/signin",
    name: "signIn",
    component: SignIn,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/signout",
    name: "SignOut",
    component: SignOut,
  },
  {
    path: "/userProfile",
    name: "UserProfile",
    component: UserProfile,
    // beforeEnter: useRouteGuard,
  },
  {
    path: "/enablemfa",
    name: "EnableMFA",
    component: EnableMFA,
    beforeEnter: useRouteGuard,
  },
  {
    path: "/cat24",
    component: cat24SideBar,
    children: [
      {
        path: "/assetsUpload",
        name: "AssetsBulkUploader",
        component: AssetsBulkUploader,
        beforeEnter: useRouteGuard,
      },
      {
        path: "/contactsUpload",
        name: "ContactsBulkuploader",
        component: ContactsBulkUploader,
        beforeEnter: useRouteGuard,
      },
      {
        path: "/contactsUpload",
        name: "ContactsBulkuploader",
        component: ContactsBulkUploader,
        beforeEnter: useRouteGuard,
      },
      {
        path: "/BookingSlots",
        name: "BookingSlots",
        component: BookingSlots,
        beforeEnter: useRouteGuard,
      },
      {
        path: "/OrdersBulkUploader",
        name: "OrdersBulkUploader",
        component: OrdersBulkUploader,
        beforeEnter: useRouteGuard,
      },
    ],
  },
  {
    path: "/admin",
    component: adminSideBar,
    children: [
      {
        path: "/Permissions",
        name: "AdminPermissions",
        component: adminPermissions,
        beforeEnter: useRouteGuard,
      },
      {
        path: "/Roles",
        name: "AdminRoles",
        component: adminRoles,
        beforeEnter: useRouteGuard,
      },
      {
        path: "/Customers",
        name: "AdminCustomers",
        component: adminCustomers,
        beforeEnter: useRouteGuard,
      },
      {
        path: "/Groups",
        name: "AdminGroups",
        component: adminGroups,
        beforeEnter: useRouteGuard,
      },
      {
        path: "/Users",
        name: "AdminUsers",
        component: adminUsers,
        beforeEnter: useRouteGuard,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
