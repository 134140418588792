<template>
  <h4 v-if="props.edit">Edit Record</h4>
  <h4 v-else>Add Record</h4>
  <template v-for="field in formFields" :key="field.value">
    <v-text-field
      class="mt-2"
      v-if="field.required"
      :label="field.text + ' (Required)'"
      v-model="setFields[field.value]"
      :rules="[rules.required]"
      density="compact"
      hide-details="auto"
    />
    <v-text-field
      v-else
      :label="field.text"
      v-model="setFields[field.value]"
      density="compact"
    />
  </template>
  <v-row class="mt-3">
    <v-col><v-btn :disabled="saving" @click="save()">Save</v-btn></v-col>
    <v-col><v-spacer></v-spacer></v-col>
    <v-col class="text-right"
      ><v-btn @click="$emit('cancel')">Cancel</v-btn></v-col
    >
  </v-row>
  <v-alert class="mt-3" v-if="alert != ''" type="error" icon="mdi-alert">{{
    alert
  }}</v-alert>
</template>

<script setup>
import { ref, reactive, onMounted, defineProps, defineEmits } from "vue";
import {
  assetsHeaders,
  contactsHeaders,
  deliveryHeaders,
  orderHeaders,
} from "./cat24Types";
import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";

const appBar = appBarStore();
const alert = ref("");

const props = defineProps({
  addType: String,
  edit: Boolean,
  item: {},
});

const emit = defineEmits(["cancel", "updated"]);

const formFields = reactive([]);
const setFields = reactive({});
const rules = { required: (value) => !!value || "Required" };
const saving = ref(false);

async function save() {
  var payload;
  saving.value = true;
  alert.value = "";
  if (props.edit) {
    payload = {
      apiAction: "update",
      customer: appBar.selected,
      [props.addType]: setFields,
    };
  } else {
    payload = {
      apiAction: "add",
      customer: appBar.selected,
      [props.addType]: [setFields],
    };
  }
  try {
    const result = await apiQuery("ELA", "cat24/" + props.addType, payload);
    console.log(result);
    emit("updated");
  } catch (error) {
    alert.value = "Error saving record";
    console.log(error);
    saving.value = false;
  }
}

onMounted(() => {
  switch (props.addType) {
    case "assets":
      console.log("Assets Selected");
      assetsHeaders.forEach((element) => {
        formFields.push(element);
      });
      break;
    case "contacts":
      contactsHeaders.forEach((element) => {
        formFields.push(element);
      });
      break;
    case "deliveries":
      deliveryHeaders.forEach((element) => {
        formFields.push(element);
      });
      break;
    case "orders":
      orderHeaders.forEach((element) => {
        formFields.push(element);
      });
      break;
    default:
      console.log("No addType Prop set");
  }

  console.log(props.item);
  if (props.item && props.edit) {
    Object.keys(props.item).forEach((key) => {
      console.log(key);
      setFields[key] = props.item[key];
    });
    // props.item.forEach((element) => {
    //   console.log(element);
    // });
  }
});
</script>
