interface cat24Header {
  text: string;
  value: string;
  required: boolean;
}

const assetsHeaders: cat24Header[] = [
  { text: "Contact Email Address", value: "contactEmail", required: true },
  { text: "Serial Number", value: "serialNumber", required: true },
  { text: "Make", value: "make", required: true },
  { text: "Model", value: "model", required: true },
  { text: "Unique Identifier", value: "uniqueIdentifier", required: true },
  { text: "IMEIs", value: "imeis", required: true },
];

const contactsHeaders: cat24Header[] = [
  { text: "Email Address", value: "email", required: true },
  { text: "First Name", value: "firstName", required: true },
  { text: "Last Name", value: "lastName", required: true },
  { text: "Keywords", value: "keyWords", required: true },
];

// const deliveryHeaders: cat24Header[] = [
//   { text: "Delivery Date", value: "deliveryDate", required: true },
//   { text: "Max Deliveries", value: "maxDeliveries", required: true },
//   {
//     text: "Reschedule Allowance",
//     value: "rescheduleAllowance",
//     required: true,
//   },
// ];

const deliveryHeaders: cat24Header[] = [
  { text: "Slot Date (YYYY-MM-DD)", value: "slotDate", required: true },
  { text: "Slot Time (HH:MM:SS)", value: "slotTime", required: true },
  { text: "Max Deliveries", value: "maxDeliveries", required: true },
  {
    text: "Reschedule Allowance",
    value: "rescheduleAllowance",
    required: true,
  },
];

const orderHeaders: cat24Header[] = [
  { text: "Order ID", value: "orderId", required: true },
  { text: "Contact Email", value: "contactEmail", required: true },
];

export {
  cat24Header,
  assetsHeaders,
  contactsHeaders,
  deliveryHeaders,
  orderHeaders,
};
//export interface cat24Header;
