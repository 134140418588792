import { defineStore } from "pinia";

export const appBarStore = defineStore("appBar", {
  state: () => ({
    type: "asdf",
    items: [],
    header: "asdf",
    appRunning: "",
    selected: "",
  }),
});
