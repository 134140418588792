<script setup>
import { ref, onMounted } from "vue";
import { Auth } from "aws-amplify";

function signIn() {
  Auth.federatedSignIn();
}

// onMounted(() => {
//   Auth.federatedSignIn();
// });
</script>

<template>
  <v-main>
    <h3>You must sign in</h3>
    <h3 @click="signIn">Sign In</h3>
  </v-main>
</template>
