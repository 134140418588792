import { Auth, API } from "aws-amplify";
import axios from "axios";

export async function apiQueryAxios(
  name: string,
  endpoint: string,
  payload: any
) {
  const user = await Auth.currentAuthenticatedUser();
  console.log(user);
  const token = user.signInUserSession.idToken.jwtToken;
  const payloadValue = {
    ...payload,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
      "Access-Control-Allow-Origin": "*",
    },
  };
  console.log(payloadValue);

  const reply = await axios.get(
    "https://8wbfq62cch.execute-api.eu-west-1.amazonaws.com/default/TestFunctionCognito",
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );

  //console.log(reply);
  //return await API.get(name, endpoint, payloadValue);
}

export async function apiQuery(name: string, endpoint: string, payload: any) {
  const user = await Auth.currentAuthenticatedUser();
  console.log(user);
  const token = user.signInUserSession.idToken.jwtToken;
  const payloadValue = {
    //...payload,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
      // "Access-Control-Allow-Credentials": true,
      // "Access-Control-Allow-Headers": "Content-Type",
      // "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
      // "Access-Control-Allow-Origin": "*",
    },
    body: payload,
  };
  console.log(payloadValue);
  return await API.post(name, endpoint, payloadValue);
}

// export default {
//   apiQuery
// };
