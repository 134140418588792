<script setup>
import { Auth } from "aws-amplify";
import { ref } from "vue";

const username = ref("");
const password = ref("");

var user = {};

async function login() {
  user = await Auth.signIn(username.value, password.value);
  console.log(user);

  if (user.challengeName == "SOFTWARE_TOKEN_MFA") {
    displayTOTP.value = true;
  }
}

const displayTOTP = ref(false);
const totopCode = ref("");

async function mfaSet() {
  const loggedUser = await Auth.confirmSignIn(
    user,
    totopCode.value,
    user.challengeName
  );
  const asdf = await Auth.confirm;
}

async function resetMFA() {
  const mfaCreationCode = await Auth.setupTOTP(user);
  console.log(mfaCreationCode);
}
</script>

<template>
  <v-main>
    <h3>Login test</h3>
    <v-text-field v-model="username" label="Username"></v-text-field>
    <v-text-field v-model="password" label="Password"></v-text-field>
    <v-btn @click="login">Login</v-btn>
    <template v-if="displayTOTP">
      <v-text-field v-model="totopCode" label="MFA Code"></v-text-field>
      <v-btn @click="mfaSet()">Send</v-btn>
    </template>
    <p>
      <v-btn @click="resetMFA">Reset MFA</v-btn>
    </p></v-main
  >
</template>
