import { watchEffect } from "vue";
import { RouteLocationNormalized } from "vue-router";
import router from "@/router";
import { Auth } from "aws-amplify";

async function useRouteGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  console.log("routeguard starting verify");
  Auth.currentAuthenticatedUser()
    .then((user) => {
      console.log("User Authenticated");
      if (user.signInUserSession?.idToken?.payload?.identities) {
        console.log("SAML User");
        return true;
      } else {
        console.log("Standard User");
        if (to.name == "EnableMFA") {
          return true;
        }
        if (user.preferredMFA != "SOFTWARE_TOKEN_MFA") {
          console.log("User not MFA Enabled");
          //router.push("EnableMFA");
        }
      }
      return true;
    })
    .catch(() => {
      console.log("User Not Authenticated");
      router.push("signIn");
      return false;
    });
}

export { useRouteGuard };
