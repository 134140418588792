<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import router from "@/router";

function goTo(link) {
  router.push({ name: link });
}

const items = ref([
  { title: "Permissions", icon: "mdi-account-key", link: "AdminPermissions" },
  { title: "Roles", icon: "mdi-account-key", link: "AdminRoles" },
  { title: "Customers", icon: "mdi-account-key", link: "AdminCustomers" },
  { title: "Groups", icon: "mdi-account-key", link: "AdminGroups" },
  { title: "Users", icon: "mdi-account-key", link: "AdminUsers" },
]);
</script>

<template>
  <v-navigation-drawer expand-on-hover absolute rail permanent>
    <v-list>
      <v-list-item v-for="item in items" :key="item.title" link>
        <v-list-item-avatar left @click="goTo(item.link)">
          <v-icon class="pa-5">{{ item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-title @click="goTo(item.link)">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <v-main>
    <router-view />
  </v-main>
</template>
