<template>
  <v-navigation-drawer permanent width="190" color="white">
    <v-list theme="whiteTheme">
      <div class="v-list-item">
        <v-icon class="pa-3" color="#e7541e">mdi-truck-fast</v-icon>
        <div class="text-h7 pa-3 cursor-default">
          <strong>CAT24 Admin</strong>
        </div>
      </div>
      <template v-for="item in items" :key="item.title">
        <v-list-item link :to="{ name: item.link }">
          <v-list-item-avatar left>
            <v-icon class="pa-2">{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
  <v-main>
    <v-container fluid>
      <router-view />
    </v-container>
  </v-main>
</template>

<style scoped>
.v-navigation-drawer__content {
  background: white;
  border: none;
  border-style: none;
}
.v-navigation-drawer {
  border: none;
  border-style: none;
  background-color: white;
}
.cursor-default {
  cursor: default;
}
</style>

<script setup>
import { userRouter } from "vue-router";
import { onMounted } from "vue";
import { appBarStore } from "@/stores/appBar";
import { apiQuery } from "@/api/j24api";

const appBar = appBarStore();

onMounted(async () => {
  // Setup of Customer Selection in navbar

  if (appBar.appRunning != "CAT24Admin") {
    appBar.$patch({
      type: "select",
      header: "Loading Please Wait...",
      appRunning: "CAT24Admin",
      items: [],
      selected: "",
    });

    const customers = await apiQuery("ELA", "cat24/getcustomers");
    console.log("CAT24 Customers are");
    console.log(customers);
    appBar.$patch({
      type: "select",
      header: "Select Customer",
      appRunning: "CAT24Admin",
      items: customers,
      selected: "",
    });
  }
});

const items = [
  // { title: "Assets", icon: "mdi-archive", link: "AssetsBulkUploader" },
  {
    title: "Contacts",
    icon: "mdi-contacts",
    link: "ContactsBulkuploader",
    value: false,
  },
  {
    title: "Booking Slots",
    icon: "mdi-truck-fast",
    link: "BookingSlots",
    value: false,
  },
  {
    title: "Orders",
    icon: "mdi-file-document-multiple",
    link: "OrdersBulkUploader",
    value: false,
  },
];
</script>
